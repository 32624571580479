import React from 'react'
import Blog from '../components/blog'
import Maintitle from '../components/maintitle'

const blogpage = () => {
  Maintitle("WebSolex-Blog Page")
  return (
    <>
    <Blog />
    </>
  )
}

export default blogpage
