import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import reactjs from "../Assets/library.png";
import nodejs from "../Assets/nodejs2.png";
import php from "../Assets/php2.png";
import laravel from "../Assets/laravel1.png";
import wordPress from "../Assets/wordpress-logo.png";
import shopify from "../Assets/shofify.png";
import angular from "../Assets/angular.png";
import wix from "../Assets/wix.png";
import webflow from "../Assets/webflow.png";
import uiux from "../Assets/ui ux.png";
import grafic from "../Assets/grafic.png";
import branding from "../Assets/branding.png";
import packging from "../Assets/package.png";
import website from "../Assets/website.png";
import application from "../Assets/application.png";
import ios from "../Assets/ios.png";
import android from "../Assets/android (1).png";
import logodesign from "../Assets/logo-design.png";
import flutter from "../Assets/flutter2.png";
import seo from "../Assets/seo.png";
import click_through from "../Assets/click-through-rate.png";
import marketing from "../Assets/marketing (1).png";
import bullhorn from "../Assets/bullhorn.png";
import digital_marketing from "../Assets/digital-marketing (1).png";
import Maintitle from "../components/maintitle";

function service() {
  Maintitle("WebSolex-Service Page");
  return (
    <>
      <section className="service_page py-100">
        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-lg-12 text-center py-5">
              <div className="section_title portfolio_title text-center mt-3 mb-4">
                <div className="section_main_title ">
                  <h1 className="text-capitalize fw-bold">
                    Explore by catagory
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0 ">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start mb-3 ">
                        <img
                          src={reactjs}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        React.js Development
                      </h5>
                      <h6 className="col-xxl-9">
                        Build Modern Web Applications with React.js
                      </h6>
                      <p className="mb-1 fs-14">
                        We specialize in crafting dynamic, responsive, and
                        high-performance web applications using React.js. Our
                        solutions are tailored to enhance user experience and
                        ensure seamless scalability.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0 ">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={nodejs}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Node.js Development
                      </h5>
                      <h6 className="col-xxl-9">
                        Efficient Backend Solutions with Node.js
                      </h6>
                      <p className="mb-1">
                        Leverage the power of Node.js for building fast,
                        scalable, and efficient server-side applications. Our
                        team delivers robust back-end solutions that support
                        real-time data and high traffic.
                      </p>
                    </div>
                    <div className="social d-flex mt-5 mt-md-4  justify-content-end">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0 ">
                    <div className="card_body_sub ">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={php}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        PHP Development
                      </h5>
                      <h6 className=" col-lg-9">
                        Versatile Web Solutions with PHP
                      </h6>
                      <p className="mb-1 fs-14">
                        Our PHP development services offer versatile and
                        reliable web solutions. From dynamic websites to complex
                        web applications, we provide efficient, secure, and
                        scalable PHP development.
                      </p>
                    </div>
                    <div className="social d-flex  mt-5 mt-md-4 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={laravel}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Laravel Development
                      </h5>
                      <h6 className="col-lg-9">
                        Elegant and Efficient Laravel Solutions
                      </h6>
                      <p className="mb-1 fs-14">
                        Utilize the elegance and efficiency of Laravel for your
                        web applications. We deliver robust, scalable, and
                        maintainable solutions using this popular PHP framework,
                        tailored to your business needs.
                      </p>
                    </div>
                    <div className="social d-flex mt-4 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={wordPress}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        WordPress Development
                      </h5>
                      <h6 className="col-lg-9">
                        Customized WordPress Websites
                      </h6>
                      <p className="mb-1 fs-14">
                        Create a strong online presence with our WordPress
                        development services. We design and develop custom
                        WordPress websites that are user-friendly,
                        SEO-optimized, and easy to manage.
                      </p>
                    </div>
                    <div className="social d-flex mt-5 mt-md-4 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={shopify}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Shopify Development
                      </h5>
                      <h6 className="col-xxl-9">
                        Ecommerce Excellence with Shopify
                      </h6>
                      <p className="mb-1 fs-14">
                        Transform your ecommerce business with our Shopify
                        development services. We create stunning, user-friendly
                        online stores that offer seamless shopping experiences
                        and are optimized for conversions.
                      </p>
                    </div>
                    <div className="social d-flex mt-4 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={angular}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Angular Development
                      </h5>
                      <h6 className="col-xxl-9">
                        Powerful Single-Page Applications with Angular
                      </h6>
                      <p className="mb-1 fs-14">
                        Our Angular development services focus on building
                        powerful, feature-rich single-page applications. We
                        ensure your web applications are fast, responsive, and
                        user-friendly
                      </p>
                    </div>
                    <div className="social d-flex mt-5 mt-md-4 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={wix}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        Wix Development
                      </h5>
                      <h6 className="col-lg-9">
                        Easy and Elegant Websites with Wix
                      </h6>
                      <p className="mb-1 fs-14">
                        Create beautiful, functional websites effortlessly with
                        our Wix development services. We provide custom design
                        and development to match your brand's unique style and
                        requirements.
                      </p>
                    </div>
                    <div className="social d-flex mt-4 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={webflow}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Webflow Development
                      </h5>
                      <h6 className="col-lg-9">
                        Advanced Web Design with Webflow
                      </h6>
                      <p className="mb-1 fs-14">
                        Unleash your creativity with our Webflow development
                        services. We build visually stunning and highly
                        functional websites that are fully responsive and
                        optimized for performance.
                      </p>
                    </div>
                    <div className="social d-flex mt-5 mt-md-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={logodesign}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        Logo Design
                      </h5>
                      <h6 className="col-lg-9">Crafting Memorable Logos</h6>
                      <p className="mb-1 fs-14">
                        Our logo design service creates unique and memorable
                        logos that perfectly represent your brand's identity. We
                        focus on simplicity, versatility, and timeless appeal to
                        make your brand stand out.
                      </p>
                    </div>
                    <div className="social d-flex mt-5 mt-md-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={grafic}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Infographic Design
                      </h5>
                      <h6 className="col-lg-9">
                        Visual Storytelling with Infographics
                      </h6>
                      <p className="mb-1 fs-14">
                        Transform complex information into engaging visuals with
                        our infographic design service. We create clear,
                        informative, and visually appealing infographics that
                        effectively communicate your message.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={packging}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        Packaging Design
                      </h5>
                      <h6 className="col-lg-9">
                        Innovative Packaging Solutions
                      </h6>
                      <p className="mb-1 fs-14">
                        Enhance your product's appeal with our packaging design
                        service. We design eye-catching, functional, and
                        brand-aligned packaging that not only attracts customers
                        but also communicates your product's value.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={branding}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        Branding Identity
                      </h5>
                      <h6 className="col-lg-9">
                        Building Strong Brand Identities
                      </h6>
                      <p className="mb-1 fs-14">
                        Establish a powerful brand presence with our
                        comprehensive branding identity service. From logo
                        creation to visual style and messaging, we help define
                        and communicate your brand's unique story and values.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={website}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        Website Design
                      </h5>
                      <h6 className="col-lg-9">
                        Stunning and Functional Websites
                      </h6>
                      <p className="mb-1 fs-14">
                        We create visually stunning and user-friendly websites
                        tailored to your brand and business needs. Our designs
                        focus on aesthetics, functionality, and seamless user
                        experience, ensuring your online presence stands out.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={application}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        Application Design
                      </h5>
                      <h6 className="col-lg-9">Innovative App Designs</h6>
                      <p className="mb-1 fs-14">
                        Our application design service delivers intuitive and
                        engaging designs for mobile and web applications. We
                        prioritize user experience and usability, creating apps
                        that are both beautiful and easy to navigate.
                      </p>
                    </div>
                    <div className="social d-flex mt-4 mt-lg-5 justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={uiux}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        UI/UX Design
                      </h5>
                      <h6 className="col-lg-9">Exceptional UI/UX Design</h6>
                      <p className="mb-1 fs-14">
                        Enhance your digital products with our expert UI/UX
                        design services. We design interfaces that are not only
                        visually appealing but also intuitive and user-centric,
                        ensuring a seamless and enjoyable experience for your
                        users.
                      </p>
                    </div>
                    <div className="social d-flex mt-4 mt-lg-5  justify-content-end gap-1">
                      <FaArrowRight className="arrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={ios}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold col-12 col-lg-10 text-capitalize mb-2">
                        iOS Development
                      </h5>
                      <h6 className="col-lg-9">
                        Exceptional iOS App Development
                      </h6>
                      <p className="mb-1 fs-14">
                        We specialize in creating high-quality iOS apps that
                        offer seamless performance and a great user experience.
                        Our team crafts custom iOS solutions that are visually
                        appealing, intuitive, and optimized for the latest Apple
                        devices.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={android}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Android Development
                      </h5>
                      <h6 className="col-lg-9">
                        Innovative Android App Solutions
                      </h6>
                      <p className="mb-1 fs-14">
                        Our Android development service delivers innovative and
                        reliable apps tailored to your business needs. We build
                        feature-rich, secure, and user-friendly Android
                        applications that run smoothly on a wide range of
                        devices.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={flutter}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Flutter Development
                      </h5>
                      <h6 className="col-xxl-9">
                        Cross-Platform Excellence with Flutter
                      </h6>
                      <p className="mb-1 fs-14">
                        Leverage the power of Flutter to create high-performance
                        apps for both iOS and Android. Our Flutter development
                        service ensures a consistent and native-like experience
                        across platforms, with beautiful UI and robust
                        functionality.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={seo}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        SEO
                      </h5>
                      <h6 className="col-xxl-9">
                        Exceptional iOS App Development
                      </h6>
                      <p className="mb-1 fs-14">
                        Rank higher on search engines and attract more organic
                        traffic with our expert SEO strategies tailored to your
                        business.
                      </p>
                    </div>
                    <div className="social d-flex mt-5  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub  pb-md-4 pb-xl-0">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={click_through}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Pay Per Click Advertising
                      </h5>
                      <h6 className="col-xxl-9">
                        Exceptional iOS App Development
                      </h6>
                      <p className="mb-1 fs-14">
                        Maximize your ROI with targeted PPC campaigns that drive
                        immediate and measurable results.
                      </p>
                    </div>
                    <div className="social d-flex mt-5  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={marketing}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Social Media Marketing
                      </h5>
                      <h6 className="col-xxl-9">
                        Exceptional iOS App Development
                      </h6>
                      <p className="mb-1 fs-14">
                        Engage your audience and grow your brand with impactful
                        social media strategies across all major platforms.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={bullhorn}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        Email Marketing
                      </h5>
                      <h6 className="col-xxl-9">
                        Exceptional iOS App Development
                      </h6>
                      <p className="mb-1 fs-14">
                        Reach your customers directly with personalized email
                        campaigns that convert leads into loyal clients.
                      </p>
                    </div>
                    <div className="social d-flex mt-5  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-7 d-flex align-items-stretch mb-5">
              <div className="position-relative  mg-10 mx-md-3 col-12">
                <div className="card border-0 mb-0">
                  <div className="card-body text-center text-lg-start p-4 border-0">
                    <div className="card_body_sub">
                      <div className="card_icon rounded-1 d-flex align-items-center justify-content-center justify-content-lg-start  mb-3">
                        <img
                          src={digital_marketing}
                          alt=""
                          className="fs-2  img-fluid col-2 col-md-3 col-lg-2"
                        />
                      </div>
                      <h5 className="mb-1 fw-bold  text-capitalize mb-2">
                        WhatsApp Marketing
                      </h5>
                      <h6 className="col-xxl-9">
                        Exceptional iOS App Development
                      </h6>
                      <p className="mb-1 fs-14">
                        Connect with your audience instantly through WhatsApp,
                        delivering messages that drive action and build
                        relationships.
                      </p>
                    </div>
                    <div className="social d-flex mt-4  justify-content-end gap-1">
                      <a href="javascript:void(0);">
                        <FaArrowRight className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default service;
