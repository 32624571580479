import React from 'react'
import From from '../components/From'
import Maintitle from '../components/maintitle'

function Contact() {
  Maintitle("WebSolex-Contact Page")
  return (
    <>
    <From />
    </>
  )
}

export default Contact
