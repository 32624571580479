import React from 'react'
import Bussines from '../components/bussines'
import Maintitle from '../components/maintitle'

function businesspage() {
  Maintitle("WebSolex-Businesspage Page")
  return (
    <div>
      <Bussines/>
    </div>
  )
}

export default businesspage
