import React from 'react'
import Careersection from '../components/careersection'
import Maintitle from '../components/maintitle'

const Career = () => {
  Maintitle("WebSolex-Career Page")
  return (
   <>
   <Careersection />
   </>
  )
}

export default Career
